<!--
 * @Author: your name
 * @Date: 2021-11-25 18:21:19
 * @LastEditTime: 2022-01-26 10:38:41
 * @LastEditors: Please set LastEditors
 * @Description: 作物状态可视化
 * @FilePath: \new-website-1123\src\views\programme\production\core\visualization.vue
-->
<template>
  <div class="core-visualization opacity-page scale-page">
    <c-title>{{ title }}</c-title>
    <p class="desc-p desc-1">
      <slot name="text1"></slot>
    </p>
    <p class="desc-p">
      <slot name="text2"></slot>
    </p>
    <div class="img-div">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import cTitle from "./title.vue";
export default {
  name: "core-visualization",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    cTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.desc-p {
  width: 1140px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba($color: #fff, $alpha: 0.8);
  line-height: 40px;
  margin: auto;
  text-align: center;
  &.desc-1 {
    margin-top: 49px;
  }
}
@media screen and (max-width: 1400px) {
  .desc-p {
    font-size: 18px;
  }
}
.img-div {
  width: 78vw;
  margin: auto;
  margin-top: 38px;
  display: flex;
  justify-content: center;
  ::v-deep {
    img {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .desc-p {
    width: 86%;
    font-size: 0.27rem;
    line-height: 0.36rem;
    &.desc-1 {
      margin-top: 0.36rem;
    }
  }
  .img-div {
    width: 86%;
    margin-top: 0.35rem;
  }
}
</style>
