<!--
 * @Author: your name
 * @Date: 2021-11-25 17:37:45
 * @LastEditTime: 2023-02-10 15:48:16
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 生产应用场景
 * @FilePath: \new-website-1123\src\views\programme\production\scene.vue
-->
<template>
  <div class="production-scene" v-show="dataList.length>0">
    <p-title>
      应用
      <template v-slot:color>场景</template>
    </p-title>
    <div class="content-div">
      <swiper class="right-div swiper" ref="swiper" :options="swiperOptions" v-if="dataList.length>0">
        <swiper-slide v-for="item in dataList" :key="item.key">
          <img class="data-img" :src="item.cover" alt="" />
        </swiper-slide>

        <div class="swiper-pagination home-pagination" slot="pagination"></div>
      </swiper>

      <div class="nav-box">
        <s-nav
          :nav-list="dataList"
          :active="navActive"
          @change="navChange"
        ></s-nav>
      </div>
    </div>
  </div>
</template>

<script>
import pTitle from "../components/title.vue";
import sNav from "../../Home/product/nav.vue";
import {getBannerList}from '@/utils/getBanner.js'
export default {
  name: "",
  components: {
    pTitle,
    sNav,
  },
  data() {
    let self = this;
    let obj = {
      autoplay: false,
      loop: true,
      speed: 800,
      mousewheel: false,
      slidesPerGroup: 1,
      spaceBetween: 20,
      on: {
        slideChange() {
          self.navActive = self.dataList[this.realIndex].id;
        },
      },

    };
    if (window.innerWidth < 800) {
      obj.pagination = {
        el: ".swiper-pagination",
        clickable: true,
      };
    }

    return {
      swiperOptions: obj,
      dataList: [
        // {
        //   key: 1,
        //   id: 1,
        //   label: "四情监防",
        //   cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/scene6.jpg",
        // },
        // {
        //   key: 2,
        //   id: 2,
        //   label: "土壤改良",
        //   cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/scene7.jpg",
        // },
        // {
        //   key: 3,
        //   id: 3,
        //   label: "种植规划",
        //   cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/scene3.jpg",
        // },
        // {
        //   key: 4,
        //   id: 4,
        //   label: "产量预测",
        //   cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/scene4.jpg",
        // },
        // {
        //   key: 5,
        //   id: 5,
        //   label: "智慧仓储",
        //   cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/scene1.jpg",
        // },
        // {
        //   key: 6,
        //   id: 6,
        //   label: "安全溯源",
        //   cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/scene5.jpg",
        // },
        // {
        //   key: 7,
        //   id: 7,
        //   label: "市场分析",
        //   cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/scene8.jpg",
        // },
        // {
        //   key: 8,
        //   id: 8,
        //   label: "农业电商",
        //   cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/scene2.jpg",
        // },

      ],
      navActive: 1,
    };
  },
  created() {
    this.getBanner()
  },
  methods: {
    getBanner(){
      getBannerList('ZHSCGL001Ban008').then(res=>{
        this.dataList = res
      })
    },
    navChange(val) {
      this.navActive = val;
      let arr = this.dataList.map(item => item.id);
      this.$refs.swiper.$swiper.slideTo(arr.indexOf(val) + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.production-scene {
  padding-top: 80px;
  padding-bottom: 50px;
  overflow: hidden;
  width: 100%;
  .content-div {
    margin-top: 80px;
    .nav-box {
      width: 78vw;
      margin: 50px auto 0;
      ::v-deep {
        .product-nav li {
          padding-bottom: 30px;
        }
      }
    }
  }
}
.cont-box {
  position: absolute;
  bottom: 55px;
  left: 100px;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s;
  opacity: 0;
  .title-span {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .desc-span {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 40px;
  }
}

.filter-div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #161616;
  opacity: 0.4;
  transition: opacity 0.3s;
}

.home-pagination {
  bottom: -30px;
  ::v-deep {
    .swiper-pagination-bullet {
      width: 24px;
      height: 3px;
      border-radius: 0;
      background-color: rgba($color: #fff, $alpha: 0.8);
      &.swiper-pagination-bullet-active {
        background-color: $color-active;
      }
    }
  }
}
::v-deep {
  .swiper-container {
    overflow: visible !important;
    width: 78vw;
    height: 600px;
  }
  .swiper-container .swiper-wrapper .swiper-slide {
    width: 78vw;
    position: relative;
    overflow: hidden;
  }
  .swiper-container .swiper-wrapper .swiper-slide-prev {
    height: 600px !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide-prev img {
    height: 600px !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide-next {
    height: 600px !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide-next img {
    height: 600px !important;
  }
  .swiper-container .swiper-wrapper .swiper-slide-active {
    width: 600px;
    .filter-div {
      // background-color: rgba($color: #161616, $alpha: 0);
      opacity: 0;
    }
    .cont-box {
      opacity: 1;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .production-scene {
    padding-top: 0.55rem;
    padding-bottom: 0.36rem;
    .content-div {
      margin-top: 0.45rem;
      .nav-box {
        width: 86vw;
        margin: 0.36rem auto 0;
        ::v-deep {
          .product-nav li {
            padding-bottom: 0.27rem;
          }
        }
      }
    }
  }

  .cont-box {
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s;
    opacity: 0;
    padding: 0.38rem 0.25rem;
    background-color: rgba($color: #000000, $alpha: 0.4);
    .title-span {
      font-size: 0.36rem;
      line-height: 0.36rem;
      margin-bottom: 0.27rem;
    }
    .desc-span {
      font-size: 0.18rem;
      line-height: 0.36rem;
      color: rgba($color: #fff, $alpha: 0.7);
    }
  }

  .nav-box {
    display: none;
  }

  ::v-deep {
    .swiper-container {
      overflow: visible !important;
      width: 86vw;
      height: 5.45rem;
    }
    .swiper-container .swiper-wrapper .swiper-slide {
      width: 86vw;
      position: relative;
      overflow: hidden;
    }
    .swiper-container .swiper-wrapper .swiper-slide-prev {
      height: 5.45rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-prev img {
      height: 5.45rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-next {
      height: 5.45rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-next img {
      height: 5.45rem !important;
    }
    .swiper-container .swiper-wrapper .swiper-slide-active {
      width: 5.45rem;
      .filter-div {
        // background-color: rgba($color: #161616, $alpha: 0);
        opacity: 0;
      }
      .cont-box {
        opacity: 1;
      }
    }
  }
}
</style>
