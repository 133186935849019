<!--
 * @Author: your name
 * @Date: 2021-11-25 11:30:01
 * @LastEditTime: 2023-02-10 15:45:10
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 解决方案 智慧生产
 * @FilePath: \new-website-1123\src\views\programme\production\index.vue
-->
<template>
  <div class="programme-production">
    <div class="banner-box">
      <w-banner :url="banner">
        <div class="banner-content-div">
          <span class="title-span">智慧生产管理解决方案</span>
          <span class="desc-span"
            >以海量的作物数据库与病虫害数据库为基础，覆盖农业种植的产前、产中、产后三阶段，</span
          >
          <span class="desc-span"
            >实现农业种植管理智慧化。以完善的作物数据模型结合实时三维场景，实现产前的可视化种植规划。以品类齐全的智慧化物联设备，</span
          >
          <span class="desc-span"
            >实现产中的智能耕、种、管、收全覆盖。配合智慧仓储、农业电商、质量溯源</span
          >
          <span class="desc-span"
            >实现农业种植产后管理。真正实现农业生产智慧化管理。</span
          >
        </div>
      </w-banner>
    </div>
    <!-- 方案优势 -->
    <div></div>
    <p-advantage
      class="opacity-page scale-page"
      :class="clsCurrent"
    ></p-advantage>
    <!-- 应用场景 -->
    <p-scene class="opacity-page"></p-scene>
    <!-- 核心 -->
    <p-core class="opacity-page"></p-core>
    <!-- 设备 -->
    <p-device
      class="opacity-page w-device"
      :title="{
        text: '关联硬件',
        color: '设备',
      }"
      :device-list="deviceList"
      :active="deviceActive"
      @change="deviceActive = $event"
    >
      <div style="padding-top: 20px">
        <img :src="currentDevice.cover" alt="上太科技" />
        <p class="desc-p">{{ currentDevice.desc1 }}</p>
      </div>
    </p-device>

    <m-device class="m-device" :list="deviceList"></m-device>
  </div>
</template>

<script>
import wBanner from "../components/banner.vue";
import pAdvantage from "./advantage.vue";
import pScene from "./scene.vue";
import pCore from "./core";
import pDevice from "../components/device.vue";
import mDevice from "../components/deviceMobile.vue";
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "programme-production",
  components: {
    wBanner,
    pAdvantage,
    pScene,
    pCore,
    pDevice,
    mDevice,
  },
  data() {
    return {
      banner: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/banner.png",
      deviceList: [
        {
          key: 1,
          title: "全地形大负载多功能无人车",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg4.png",
          desc: "采用履带式差速机械机构，具有极强的地形适应能力，专为特殊作业环境设计，具有差速自转、载重能力强、机械损耗小、防护等级高等特点",
          path:'/special/TK101'
        },
        {
          key: 2,
          title: "智能巡检无人车",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg9.png",
          desc: "多模式多场景，自由适配,巡防无人车采用模块化、智能化设计理念，具有强大载荷能力和强劲动力系统的它可广泛应用于无人巡检、安防、科研、勘探等。 ",
          path:'/special/XJ101'
        },
        {
          key: 3,
          title: "多功能无人机",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg5.png",
          desc: "全新折叠桁架式结构，结实可靠，可高效收展，方便搬运;惊人载重能力，作业效率达到新高度，配合数字农业解决方案，科学指导减肥增产",
          path:'/special/MultifunctionalUAV'
        },
        {
          key: 4,
          title: "智能巡检无人机",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg10.png",
          desc: "手持一体显示遥控器，采用安卓系统，搭载7寸高清屏幕;高达14个控制通道设置;可收放式脚架，实时1080P数字高清图像传输接收;远程距离高度 5km，支持飞空系统数据传输;实时回传飞行器情况，支持摄像机 HDMI网口端",
          path:'/special/UAV'
        },
      ],
      deviceActive: 1,
    };
  },
  created() {},
  mounted() {
    this.addView()
  },
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
    clsCurrent() {
      // if (window.innerWidth > 800) {
      //   return "scale-page sticky-page";
      // }
      return "";
    },
  },
  methods: {
    addView(){
      getNesViewApi({type:2,pid:"17661d2235cd4f9f92d97356f6df5911"})
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  overflow: hidden;
  // height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  .banner-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding-bottom: 140px;
    .title-span {
      font-size: 54px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .desc-span {
      font-size: 22px;
      font-weight: 400;
      line-height: 40px;
    }
    @media screen and (max-width: 1400px) {
      .title-span {
        font-size: 48px;
      }
      .desc-span {
        font-size: 18px;
      }
    }
  }
}

.m-device {
  display: none;
}
.desc-p {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}

@media screen and (max-width: $mobile-width) {
  .banner-box {
    overflow: hidden;
    width: 100%;
    ::v-deep {
      .programme-banner img {
        width: 150%;
      }
    }
    .banner-content-div {
      text-align: center;
      padding-bottom: 5%;
      width: 86%;
      .title-span {
        font-size: 0.49rem;
        font-weight: bold;
        margin-bottom: 0.27rem;
      }
      .desc-span {
        font-size: 0.2rem;
        font-weight: 400;
        line-height: 0.36rem;
      }
      .banner-ul {
        margin-top: 10%;
        .banner-li {
          .icon-span {
            width: 0.36rem;
            height: 0.36rem;
            font-size: 0.36rem;
          }
          .label-span {
            margin-top: 0.18rem;
            font-size: 0.15rem;
          }
        }
      }
    }
  }

  .w-device {
    display: none;
  }
  .m-device {
    display: block;
  }
}
</style>
