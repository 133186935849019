<!--
 * @Author: your name
 * @Date: 2021-11-25 18:20:27
 * @LastEditTime: 2023-02-10 15:49:04
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 方案核心
 * @FilePath: \new-website-1123\src\views\programme\production\core\index.vue
-->

<template>
  <div class="production-core" st>
    <c-title>
      方案
      <template v-slot:color>核心</template>
    </c-title>
    <!-- 可视化 -->
    <c-visualization
      v-for="(item, index) in dataList"
      :key="item.key"
      :style="itemStyle(index)"
      :title="item.title"
    >
      <span slot="text1">{{ item.text1 }}</span>
      <span slot="text2">{{ item.text2 }}</span>
      <img v-if="item.key != '3'" :src="item.cover" alt="上太科技" />
      <template v-else>
        <div class="con-div">
          <div
            v-for="child in item.child"
            :key="'child-' + child.key"
            class="hoseactive-div"
            :class="{
              'li-active': child.key == liActive,
            }"
            @click="liActive = child.key"
          >
            <img class="data-img" :src="child.cover" alt="上太科技" />
          </div>

          <ul class="right-ul">
            <li
              v-for="child in item.child"
              :key="'child-' + child.key"
              :class="{
                'li-active': child.key == liActive,
              }"
              @click="liActive = child.key"
            >
              <span class="title-span">{{ child.label }}</span>
              <template v-if="child.key == liActive">
                <span
                  class="desc-span"
                  v-for="(lis, index1) in child.list"
                  :key="'list-' + index1"
                >
                  {{ lis }}
                </span>
              </template>
            </li>
          </ul>
        </div>
        <!-- 移动端 -->
        <s-swiper class="m-con-div" :data-list="item.child">
          <div slot-scope="data" class="m-content">
            <img :src="item.cover" alt="上太科技" />
            <div class="content">
              <span class="title-span">{{ data.row.label }}</span>
              <span
                class="desc-span"
                v-for="(item, index2) in data.row.list"
                :key="index + '-' + index2"
              >
                {{ item }}
              </span>
            </div>
          </div>
        </s-swiper>
      </template>
    </c-visualization>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
import cVisualization from "./item.vue";
import sSwiper from "../../components/swiper.vue";
export default {
  name: "production-core",
  components: {
    cTitle,
    cVisualization,
    sSwiper,
  },
  data() {
    return {
      dataList: [
        {
          key: 1,
          title: "作物状态可视化",
          text1:
            "依赖作物模型数据库,预置多种类型的农作物数据，如粮（水稻、小麦、玉米、大豆、红薯）、油（油菜、花生）、棉（棉花）、",
          text2:
            "果（柑橘、苹果、梨）、糖（甘蔗、甜菜）、茶树等，包含作物种植周期预置任务、种植技术参考、光温水气肥的需求模型等",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/core1.jpg",
        },
        {
          key: 2,
          title: "可视化种植规划",
          text1:
            "提供二维及三维种植方案规划系统。可通过直观的可视化操作界面，规划每块田地的种植计划，",
          text2: "预测丰收量等，做到种前心里有数",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/core2.png",
        },
        {
          key: 3,
          title: "农业生产管理全覆盖",
          text1:
            "覆盖产前种植方案规划、作物种植技术参考；产中耕种、种、管、收全流程数字化；",
          text2:
            "产后智慧仓储，农业电商、安全溯源一整套前中后全覆盖智慧生产管理",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/antenatal1.jpg",
          child: [
            {
              key: 1,
              label: "产前管理",
              list: [
                "作物模型数据库预置多种类型的农作物数据，包括作物的种植周期预置任务、种植技术参考、光温水气肥的需求模型等",
                "直观的种植方案规划与产量预测，可通过直观的可视化操作界面，规划每块田地的种植计划，坐到种前心里有数",
                "精准的市场供需信息，提供专业的农产品市场研究、市场分心、供应预测等行业研究报告，帮助农户合理规划种植、采摘、上市等计划",
              ],
              cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/antenatal1.jpg",
            },
            {
              key: 2,
              label: "产中管理",
              list: [
                "通过智慧种植系统，帮助农户实现耕种管收生产全流程数宇化。",
                "具有无人车、无人机智能管理、路径自动规=视觉传感辅助修正,",
                "视频回传、农资管理、绿色防控、药肥建议、产量预测、品质分级等功能",
              ],
              cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/antenatal2.jpg",
            },
            {
              key: 3,
              label: "产后管理",
              list: [
                "上太智慧仓储系统引入电子条码技术和RFID技术，实现无纸化操作，商品快速出入库、盘点功能",
                "上太农业电商平台致力于为农户提供专业、个性化农业电商服务，为农户拓展市场服务半径。拓宽更多盈利渠道；",
                "上太质量安全溯源平台是全生命周期监控的智慧溯源平台，通过与配套硬件及智慧农业云平台无缝衔接，提供耕、种、管、收、售全环节信息完善记录串联。",
              ],
              cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/antenatal3.jpg",
            },
          ],
        },
        {
          key: 4,
          title: "种植方案全面",
          text1:
            "覆盖多种作物的关键阶段数据，科学的作物种植数字模型，智能化水肥管理技术，自动监测土壤肥力和",
          text2:
            "植物营养状态，综合气象信息、苗情信息、作物生长状态等数据，智能控水施肥，形成全面等种植方案，为生产过程保驾护航",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/core4.png",
        },
        {
          key: 5,
          title: "预警方案全面",
          text1:
            "覆盖从天-空-地三位一体的立体绿色防控网络，运用大数据分析及人工智能深度学习技术，打造精准种",
          text2:
            "植管理模型，精准监测虫情、苗情、墒情、灾情，为农作物健康保驾护航",
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/core5.png",
        },
      ],
      liActive: 1,
    };
  },
  created() {},
  methods: {
    itemStyle(index) {
      if (window.innerWidth > 800) {
        return { "margin-top": index == 0 ? "40px" : "130px" };
      } else {
        return { "margin-top": index == 0 ? "0.7rem" : "1rem" };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.production-core {
  padding-top: 180px;
  padding-bottom: 50px;
  .m-con-div {
    display: none;
  }
  .con-div {
    display: flex;
    .hoseactive-div {
      img {
        display: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &.li-active {
        img {
          display: block;
        }
      }
    }
    .data-img {
      width: 70%;
    }
    .right-ul {
      width: 30%;
      width: 480px;
      display: flex;
      padding: 30px 30px 80px 80px;
      flex-direction: column;
      height: 700px;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        padding-top: 18px;
        position: relative;
        cursor: default;
        &::before {
          content: "";
          width: 56px;
          height: 3px;
          background-color: $color-active;
          position: absolute;
          left: 0;
          top: 0px;
          transition: all 0.3s;
        }
        &.li-active {
          &::before {
            width: 100px;
          }
          .title-span {
            color: $color-active;
          }
        }
        .title-span {
          font-size: 32px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .desc-span {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 0.5);
          line-height: 40px;
        }
      }
    }

    @media screen and (max-width: 1400px) {
      .right-ul {
        padding: 30px 30px 30px 30px;
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .production-core {
    padding-top: $mobile-header-height;
    padding-bottom: 0.36rem;
    width: 100%;
    overflow: hidden;
    .con-div {
      display: none;
    }
    .m-con-div {
      display: block;
      padding-top: 20px;
      ::v-deep {
        .desc-div {
          display: none;
        }

        .swiper-container {
          &.right-div {
            height: auto;
          }

          .swiper-wrapper .swiper-slide {
            height: 7.77rem !important;
          }
        }
      }
    }
    ::v-deep {
      .swiper-container .swiper-wrapper .swiper-slide-next img,
      .swiper-container .swiper-wrapper .swiper-slide-prev img {
        height: 5.45rem !important;
      }
    }
    .m-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: rgba($color: #fff, $alpha: 0.05);
      & > img {
        width: 100%;
        height: 5.45rem;
        object-fit: cover;
      }
      .content {
        padding-top: 0.33rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title-span {
          font-size: 0.31rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 0.09rem;
        }
        .desc-span {
          font-size: 0.27rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 0.5);
          line-height: 0.44rem;
        }
      }
    }
  }
}
</style>
