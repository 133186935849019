<!--
 * @Author: your name
 * @Date: 2021-11-25 17:26:19
 * @LastEditTime: 2023-02-10 15:44:12
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 生产 方案优势
 * @FilePath: \new-website-1123\src\views\programme\production\advantage.vue
-->
<template>
  <div class="production-advantage">
    <p-title>
      方案
      <template v-slot:color>优势</template>
    </p-title>
    <ul class="content-div">
      <li v-for="item in dataList" :key="item.key">
        <img :src="item.icon" alt="上太科技" />
        <span class="label-span">{{ item.label }}</span>
        <span class="desc-span">{{ item.desc }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import pTitle from "../components/title.vue";
export default {
  name: "production-advantage",
  components: {
    pTitle,
  },
  data() {
    return {
      dataList: [
        {
          key: 1,
          label: "作物状态可视化",
          desc: "三维模型库，覆盖作物生长多种阶段",
          icon: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/icon1.png",
        },
        {
          key: 2,
          label: "可视化种植规划",
          desc: "地块划分、作物种植可视化操作",
          icon: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/icon2.png",
        },
        {
          key: 3,
          label: "农业生产管理全覆盖",
          desc: "覆盖产前、产中、产后全流程",
          icon: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/icon3.png",
        },
        {
          key: 4,
          label: "种植方案全面",
          desc: "覆盖多种作物的关键阶段数据",
          icon: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/icon4.png",
        },
        {
          key: 5,
          label: "预警方案全面",
          desc: "预防多种病虫害/气象灾害",
          icon: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/production/icon5.png",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.production-advantage {
  padding-top: 100px;
  padding-bottom: 50px;
  .content-div {
    width: 1200px;
    margin: 100px auto 80px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:first-child {
        margin-bottom: 100px;
      }
      img {
        width: 76px;
        display: block;
        margin-bottom: 29px;
      }
      .label-span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 40px;
      }
      .desc-span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba($color: #fff, $alpha: 0.6);
        line-height: 40px;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .production-advantage {
    padding-top: $mobile-header-height;
    padding-bottom: 0.36rem;
    .content-div {
      width: 100%;
      margin: 0.55rem auto 0.45rem;
      justify-content: center;
      li {
        width: 48%;
        margin-bottom: 0.73rem !important;
        img {
          width: 0.69rem;
          margin-bottom: 0.26rem;
        }
        .label-span {
          font-size: 0.27rem;
          line-height: 0.36rem;
        }
        .desc-span {
          font-size: 0.16rem;
          line-height: 0.36rem;
          width: 1.84rem;
          text-align: center;
        }
      }
    }
  }
}
</style>
