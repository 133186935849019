<!--
 * @Author: your name
 * @Date: 2021-11-25 18:21:26
 * @LastEditTime: 2021-12-24 09:45:15
 * @LastEditors: Please set LastEditors
 * @Description: 核心的title
 * @FilePath: \new-website-1123\src\views\programme\production\core\title.vue
-->
<template>
  <div class="core-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.core-title {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  margin: auto;
}
@media screen and (max-width: 1400px) {
  .core-title {
    font-size: 46px;
  }
}

@media screen and (max-width: $mobile-width) {
  .core-title {
    font-size: 0.29rem;
    line-height: 0.36rem;
  }
}
</style>
