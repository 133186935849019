<!--
 * @Author: your name
 * @Date: 2021-11-24 13:59:20
 * @LastEditTime: 2022-01-26 10:15:12
 * @LastEditors: Please set LastEditors
 * @Description: 产品中心nav
 * @FilePath: \new-website-1123\src\views\Home\product\nav.vue
-->
<template>
  <ul class="product-nav">
    <li
      v-for="item in navList"
      :key="item.value"
      class="shou"
      :class="{
        'product-active': active == item.id,
      }"
      @click="$emit('change', item.id)"
    >
      {{ item.label }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "product-nav",
  props: {
    navList: {
      type: Array,
      required: true,
    },
    active: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.product-nav {
  color: rgba($color: #fff, $alpha: 0.8);
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
  font-size: 16px;
  li {
    flex: 1;
    margin-right: 10px;
    text-align: center;
    white-space: nowrap;
    padding-bottom: 8px;
    position: relative;
    transition: all 0.3s;
    &:last-child {
      margin-right: 0;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      height: 2px;
      width: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s;
      background-color: $color-active;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
    &.product-active {
      color: $color-active;
      &::after {
        width: 100%;
      }
    }
  }
}
</style>
